<template>
    <div class="select">
        <div class="table-container">
            <div class="button_container">
                学期：
                <el-select size="small" v-model="semesterId" placeholder="请选择" class="select_container input" @change="onSemester">
                    <el-option
                        v-for="item in this.$store.state.chinese.semester.list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                教材：
                <el-select size="small" v-model="bookId" placeholder="请选择" class="select_container input">
                    <el-option
                        v-for="item in this.$store.state.chinese.book.list"
                        :key="item.id"
                        :label="item.publishing"
                        :value="item.id">
                    </el-option>
                </el-select>
                单元名称：
                <el-input size="small" placeholder="请输入单元名称" class="school_input select_container input"
                          v-model="name" style="width: 150px"></el-input>
                <el-button size="small" type="primary" class="select_container" @click="onSearch">查询
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="onReset">重置
                </el-button>
                <el-button size="small" type="primary" class="select_container" @click="addUnit" v-if="hasAddBook">添加单元
                </el-button>
            </div>
            <el-table border :data="this.$store.state.chinese.unit.list">
                <el-table-column type="index" :index="indexCompute" label="序号" align="center"
                                 width="60px"></el-table-column>
                <el-table-column prop="name" label="单元名称" align="center" min-width="100px"></el-table-column>
                <el-table-column prop="publishing" label="教材名称" align="center" min-width="100px">
                </el-table-column>
                <el-table-column label="操作" align="center" width="300px">
                    <template slot-scope="scope">
                        <el-link type="primary" icon="el-icon-delete" @click="deleteUnit(scope.row)" v-if="hasDeleteBook">删除</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="updateUnit(scope.row)" v-if="hasPutBook" style="margin-left: 10px;">修改</el-link>&nbsp;&nbsp;
                        <el-link type="primary" icon="el-icon-s-operation" @click="gochapter(scope.row)" style="margin-left: 10px;">前往该教材章节</el-link>&nbsp;&nbsp;
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-container">
            <el-pagination class="pagination afterClearfix"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="pagination.page+1"
                           :page-sizes="[10, 20, 30, 50]"
                           :page-size="pagination.size"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="this.$store.state.chinese.unit.total"
                           style="float: right">
            </el-pagination>
        </div>
        <router-view/>
    </div>
</template>

<script>
    // import {getDateFromTimeStamp} from '@/libs/tools'
    import {checkPermission, getData, setData} from "@/utils/cookies";
    // import md5 from 'js-md5';
    // import {compressFile, isFloat} from "@/utils/tools";

    export default {
        name: 'List',
        data() {
            return {
                semesterId: '',//学期id
                bookId: '',//教材id
                name: '',//单元名称
                pagination: {
                    page: 0,
                    size: 10,
                },
                dialogVisible: false,//添加弹框
                modifyDialogVisible: false,//修改弹框
                hasAddBook: checkPermission("addBook"),
                hasPutBook: checkPermission("putBook"),//修改教材权限
                hasDeleteBook: checkPermission("deleteBook"),//删除教材的权限

            }
        },
        mounted() {
            this.semesterId = getData('goUnitInfo') && getData('goUnitInfo').semesterId
            this.bookId = getData('goUnitInfo') && getData('goUnitInfo').id
            this.semesterList();
            this.getBookList();
            this.getUnitList();
        },
        methods: {
            // 获取学期列表
            semesterList() {
                this.$store.dispatch('chinese/semesterList')
            },


            // 获取教材列表
            getBookList() {
                if (this.semesterId) {
                    this.$store.dispatch("chinese/bookList", {'semesterId': this.semesterId, "size": 999});
                }
            },

            // 获取单元列表
            getUnitList() {
                this.$store.dispatch("chinese/unitList", {
                    'semesterId': this.semesterId,
                    'bookId': this.bookId,
                    'name': this.name,
                    'page': this.pagination.page,
                    'size': this.pagination.size
                });
            },

            // 查询
            onSearch() {
                this.pagination.page = 0
                this.getUnitList()
            },

            // 重置
            onReset() {
                this.semesterId = undefined;
                this.bookId = undefined;
                this.name = undefined;
                this.getUnitList();
            },

            // 添加单元
            addUnit() {
                this.$router.push('/chinese/unit/add');
            },

            // 删除单元
            deleteUnit(row) {
                this.$store.dispatch("chinese/deleteUnit", {
                    'id': row.id,
                }).then((data) => {
                    this.getUnitList();
                });
            },

            // 修改教材
            updateUnit(row) {
                setData('unitInfo', row)
                this.$router.push('/chinese/unit/update');
            },

            // 前往该单元
            gochapter(row) {
                setData('goChapterInfo', row)
                this.$router.push('/chinese/chapter/chapter');
            },

            // 学期改变
            onSemester() {
                this.getBookList();
                this.bookId = undefined;
            },

            // 页大小改变
            handleSizeChange(size) {
                this.pagination.size = size;
                this.getUnitList();
            },

            // 页码改变
            handleCurrentChange(page) {
                this.pagination.page = page - 1;
                this.getUnitList();
            },

            // 列表序号
            indexCompute(index) {
                return index + 1 + this.pagination.page * this.pagination.size;
            },
        }
    }
</script>

<style scoped lang="scss">
    .select {
        padding: 0 15px;
    }

    .button_container {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        align-items: center;

        .select_container {
            margin-right: 30px;
            margin-bottom: 30px;
        }

        .input {
            width: 150px;
        }

        .school_input {
            display: inline-block;
            width: 300px;
        }

        .upload_container {
            display: inline-flex;
            width: 80px;
            height: 32px;
            position: relative;
            overflow: hidden;
            background-color: #409EFF;
            border-radius: 5px;
            color: #ffffff;
            line-height: 32px;
            justify-content: center;
            margin-right: 30px;
            font-size: 12px;
        }

        .upload_input {
            display: inline-block;
            position: absolute;
            top: 0;
            left: -500px;
            width: 580px;
            height: 32px;
            border: none;
            outline: none;
        }
    }


</style>
